.main-container{
  display: table;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.main-container .gradient-layer{
  position: relative;
  background-color: rgba(17, 10, 52, 0.8);
  background-size: cover;
  height: 100vh;
}

.main-container .gradient-layer .header {
  display: block;
  width: 100%;
}

.main-container .gradient-layer .header .logo{
  padding: 60px;
  width: 20%;
}

.main-container .gradient-layer .header .logo img{
  width: 100%;
  height: 100%;
}

.main-container .gradient-layer .data-container{
  position: absolute;
  height: 100vh;
  width: 100%;
  float: left;
}
.main-container .gradient-layer .data-container .text-container,
.main-container .gradient-layer .data-container .form-container{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.full-row-btn{
  margin: 10px;
}
.main-container .gradient-layer .data-container .text-container .welcome-text{
  width: 60%;
  padding-left: 5%;
}

.main-container .gradient-layer .data-container .text-container .welcome-text h1.mobile{
  display: none;
}

.main-container .gradient-layer .data-container .text-container h1,
.main-container .gradient-layer .data-container .text-container p{
  color: #fff;
}

.main-container .gradient-layer .form-container .form-content{
  margin: 0 auto;
  width: 80%;
  background-color: rgba(7, 8, 30, 0.52);
}

.main-container .gradient-layer .form-container .form-content .form{
  width: 90%;
  margin: 0 auto;
}

.main-container .gradient-layer .form-container .form-content .form .form-title{
  padding: 20px 0 5px 0;
  font-family: inherit;
  font-weight: normal;
  font-size: 22px;
  color: #fff;
}

.ant-radio-group .ant-radio-button-wrapper{
  background: transparent;
}

.main-container .gradient-layer .form-container .form-content .form .form-fields .ant-radio-group.ant-radio-group-solid{
  width: 100%;
  padding: 0 0 20px 0;
}

.main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper{
  text-align: center;
  width: 50%;
  color: #fff;
}

.main-container .gradient-layer .form-container .form-content .form input{
  height: 40px;
  border: none;
  border-radius: 0;
  outline: none;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}

.terms-drawer .ant-drawer-close,
.contact-drawer .ant-drawer-close{
    color: #fff;
}

.login-form span.ant-input-password input.ant-input{
  padding-left: 10px;
}

@media only screen and (min-width: 1900px){
  .main-container .gradient-layer .form-container .form-content{
    width: 60%;
  }
}

@media only screen and (max-width: 1200px){
  .main-container .gradient-layer .form-container .form-content{
    width: 60%;
  }
  .main-container .gradient-layer .data-container .text-container .welcome-text{
    width: 100%;
  }
}

@media only screen and (max-width: 992px){
  .gradient-layer{
    overflow-y: auto;
  }
  .main-container .gradient-layer .header .logo{
    width: 40%;
  }
  .main-container .gradient-layer .data-container{
    position: relative;
  }
  .main-container .gradient-layer .data-container .text-container,
  .main-container .gradient-layer .data-container .form-container{
    position: relative;
    top: 0;
    transform: unset;
  }

  .main-container .gradient-layer .data-container{
    margin-bottom: 200px;
  }

  .main-container .gradient-layer .data-container .text-container .welcome-text{
    padding: 10px 10px 20px 20px;
    margin: 0 auto;
    background-color: #fff;
    opacity: 0.5;
  }
  .main-container .gradient-layer .data-container .text-container .welcome-text h1,
  .main-container .gradient-layer .data-container .text-container .welcome-text p{
    color: #000;
  }
  .main-container .gradient-layer .data-container .text-container .welcome-text,
  .main-container .gradient-layer .data-container .form-container .form-content{
    width: 85%;
  }
  .ant-drawer-content-wrapper{
    width: 75% !important;
  }
}

@media only screen and (max-width: 600px){
  .main-container .gradient-layer .data-container .text-container .welcome-text,
  .main-container .gradient-layer .data-container .form-container .form-content{
    width: 95%;
  }
  .main-container .gradient-layer .header .logo{
    width: 60%;
    padding: 20px 0 20px 0;
    margin: 0 auto;
  }
  .main-container .gradient-layer .data-container{
    margin-bottom: 50px;
  }
  .ant-drawer-content-wrapper{
    width: 100% !important;
  }
  .ant-message{
    display: block;
    /* position: fixed !important; */
    /* bottom: 24px; */
  }

  .ant-message .ant-message-notice-content{
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }

  .ant-message .ant-message-notice-content i{
    /* display: none; */
    color: #fff;
  }

  .ant-notification{
    display: none;
  }
}

@media only screen and (max-width: 480px){
  .main-container .gradient-layer .data-container .text-container .welcome-text h1.full-screen{
    display: none;
  }
  .main-container .gradient-layer .data-container .text-container .welcome-text h1.mobile{
    display: block;
  } 
}