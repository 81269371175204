div.steps-content.search-content{
  background-color: #fff;
}
.professionals-list.ant-row{
  padding: 20px;
}
.professionals-list .ant-card{
  width: 95%;
  margin: 0 auto;
  /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */
  margin-bottom: 30px;
  text-align: left;
}

.professionals-list .ant-card .ant-card-head,
.professionals-list .ant-card .ant-card-extra{
  background-color: #1890ff;
  color: #fff;
}

.professionals-list .ant-card .ant-list-item label {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.professionals-list .ant-card .ant-list-item label i{
  padding-right: 5px;
}

.professionals-list .ant-card .ant-list-item span {
  position: absolute;
  right: 0;
  font-weight: 500;
  opacity: 0.8;
}
.professionals-list .ant-card .ant-card-actions i{
  font-size: 22px;
}
.field-error{
  color: red;
}
.search-container .ant-form-item-control-wrapper{
  text-align: left;
}
.search-form{
  margin-bottom: 50px;
}
.search-form-container{
  text-align: left;
}
.search-form-container .process-steps{
  width: 95%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.search-container .ant-radio-button-wrapper{
  height: auto;
  padding: 5px 30px 0px 30px;
}
.search-container .ant-radio-button-wrapper-checked span h4,
.search-container .ant-radio-button-wrapper-checked span h5{
  color: #fff;
}
.search-container .shifts-row .ant-divider-horizontal{
  margin: 5px 0;
}
.search-container .date-header{
  border: 1px solid #ffffff;
  background-color: #f1f1f1;
  width: 95%;
  margin: 0 auto;
  padding: 7px;
  padding-left: 20px;
}

.search-container .date-header .ant-page-header-heading-title,
.search-container .date-header .ant-page-header-heading-extra{
  color: #333;
}
.search-container .date-header .ant-page-header-heading-sub-title{
  color: #a1a0a0;
}
.search-container .empty-list{
  padding: 20px;
}
.search-container .date-header .ant-page-header-heading-extra{
  padding-top: 3px;
  padding-right: 25px;
}
.search-container .shift-cell,
.search-container .shift-name,
.search-container .shift-date{
  display: inline-block;
  padding: 10px;
  text-align: center;
  /* font-weight: 700; */
  border: 1px solid #68606012;
}
.search-container .shift-cell{
  width: 120px;
  padding: 0;
}
.search-container .shift-cell .ant-form-item-control-wrapper{
  text-align: center;
}
.search-container .shift-cell .checkbox-container  .ant-form-item{
  margin: 0;
}
.search-container .shift-name{
  width: 175px;
  color: #fff;
  background-color: #1890ff;
}
.search-container .empty-shift-cell{
  display: inline-table;
  background-color: #1890ff;
  width: 175px;
}
.search-container .shift-date{
  width: 120px;
  background-color: #1890ff;
  color: #fff;
}
.search-container .search-btn button{
  float: right;
  margin-left: 10px;
  right: 95px;
  top: 20px;
}
.professionals-list .ant-card .ant-list-item .ant-btn-primary.ant-btn-block{
  height: 50px;
}
.professionals-list .ant-card .card-title-extra{
  font-size: 12px;
  margin-left: 20px;
  color: rgba(255, 255, 255, 0.815);
}
.professionals-list .ant-card .ant-list-item .ant-btn-primary.ant-btn-block span{
  position: relative;
}
.offer-modal{
  top: 20px;
}

.offer-form-container .ant-checkbox-group-item.ant-checkbox-wrapper{
  min-width: 45%;
  line-height: 3em;
}
.price-field{
  width: 20%;
}

.shifts-row .ant-checkbox-inner{
  border: 2px solid #1e1c5d;
}
div.search-mobile-message{
  display: none;
}

@media only screen and (max-width: 1020px){
  div.search-mobile-message{
    display: block;
    width: 90%;
    margin: 0 auto;
  }
  .search-mobile-form{
    display: none;
  }
}