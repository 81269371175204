@import '~antd/dist/antd.css';

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /**  CSS for Toasts     **/
  .ant-notification-notice.message-toast{
    background-color: #1890ff;
    color: white;
  }
  .ant-notification-notice{
    background-color: #001529;
    color: #fff;
  }
.ant-notification-notice-message{
  color: #fff;
  padding-bottom: 5px;
  width: 95%;

  font-weight: 100;
}
.message-toast .ant-notification-notice-message{
  display: none;
}
.message-toast .ant-notification-notice-description{
  width: 90%;
}
a.ant-notification-notice-close{
  color: #fff;
}


.error-toast{
  background-color: rgb(19, 18, 18);
}
.error-toast .ant-notification-notice-message{
  color: #fff;
  font-weight: 100;
}
.error-toast .ant-notification-notice-close i{
  top: 19px;
  color: #fff;
}

.title-toast{
  background-color: #33327b;
}
.success-btn{
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.success-btn:hover{
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.ant-spin-nested-loading{
  position: inherit;
}
button i.anticon{
  padding-right: 5px;
}
button.ant-btn-circle i{
  padding: 0;
  margin-bottom: 3px;
  /* vertical-align: unset; */
}

.left-btn{
  position: relative;
  float: left;
  left: 0;
}

.right-btn{
  position: relative;
  float: right;
  right: 0;
}

.full-btn{
  position: relative;
  width: 100%;
  margin: 20px 0 20px 0;
}

/* body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

body::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
					   left bottom,
					   left top,
					   color-stop(0.44, rgb(122,153,217)),
					   color-stop(0.72, rgb(73,125,189)),
					   color-stop(0.86, rgb(28,58,148)));
} */

/* @media only screen and (max-width: 2000px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 80%;
    top: 160px;
  }
} */

/* @media only screen and (max-width: 1700px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 80%;
  }
}

@media only screen and (max-width: 1620px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 80%;
  }
  .main-container .gradient-layer .form-container {
    top: 120px;
  } 
}

@media only screen and (max-width: 1500px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 90%;
    top: 180px;
  }
}

@media only screen and (max-width: 1400px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 94%;
    top: 120px;
  }
  .main-container .gradient-layer .header{
    height: 220px;
  }
}

@media only screen and (max-width: 1300px) and (max-height: 1050px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 100%;
    top: 230px;
  }
  .main-container .gradient-layer .header{
    height: 310px;
  }
}

@media only screen and (max-width: 1300px) and (max-height: 900px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 100%;
    top: 140px;
  }
  .main-container .gradient-layer .header{
    height: 210px;
  }
}

@media only screen and (max-width: 1200px){
  .main-container .gradient-layer .form-container .form{
    width: 70%;
    right: 0px;
  }
  .main-container .gradient-layer .text-container{
    width: 90%;
  }
}
@media only screen and (max-width: 1050px){
  .main-container .gradient-layer .form-container .form{
    width: 80%;
    right: 0px;
  }
  .main-container .gradient-layer .text-container{
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .gradient-layer{
    overflow-y: auto;
  }
 .main-container .gradient-layer .header{
   height: 150px;
 }
 .main-container .gradient-layer .header .logo{
   width: 40%;
   margin: 0 auto;
 }
 .main-container .gradient-layer .header .logo img{
   margin-top: 50px;
 }
 .main-container .gradient-layer .text-container{
   padding: 25px;
   width: 95%;
   background-color: rgba(255, 255, 255, 0.5); 
   margin: 0 auto;
   margin-top: 40px;
 }
 
 .main-container .gradient-layer .text-container p,
 .main-container .gradient-layer .text-container h1{
    color: #000;
 }
 .main-container .gradient-layer .text-container button.contact-button{
   top: 0px;
 }
 .main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper{
   width: 275px;
 }

 .main-container .gradient-layer .form-container .contact-button{
    float: right;
    left: 130px;
    top: 15px;
 }
 .contact-drawer .ant-drawer-content-wrapper{
   width: 50% !important;
 }
 .main-container .gradient-layer .form-container .form{
   right: 0;
   top: 100px;
   width: 90%;
   margin-bottom: 125px;
  }
}

@media only screen and (max-width: 600px) {
  .gradient-layer{
    overflow-x: hidden;
  }
  .main-container .gradient-layer .text-container{
    width: 94%;
  }
  .main-container .gradient-layer .header{
    height: 100px;
  }
  .main-container .gradient-layer .header .logo{
    width: 100%;
    padding: 50px;
    padding-top: 0px;
  }
  .main-container .gradient-layer .header .logo img{
    margin-top: 20px;
  }
  .main-container .gradient-layer .text-container h1{
    text-align: center;
  }
  .main-container .gradient-layer .text-container button.contact-button i {
    display: none;
    
  }
  .main-container .gradient-layer .text-container button.contact-button,
  .main-container .gradient-layer .form-container .form{
    width: 100%;
    left: 0;
  }
  .main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper{
    width: 150px;
  }
  .main-container .gradient-layer .form-container .contact-button{
    display: none;
  }
  .main-container .gradient-layer .form-container .contact-mobile-button{
    display: block;
    width: 82%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 400px) {
  .gradient-layer{
    overflow-x: hidden;
  }
  .main-container .gradient-layer .text-container{
    width: 94%;
  }
  .main-container .gradient-layer .header{
    height: 100px;
  }
  .main-container .gradient-layer .header .logo{
    width: 100%;
    padding: 50px;
    padding-top: 0px;
  }
  .main-container .gradient-layer .header .logo img{
    margin-top: 20px;
  }
  .main-container .gradient-layer .text-container h1{
    text-align: center;
  }
  .main-container .gradient-layer .text-container button.contact-button i {
    display: none;
    
  }
  .main-container .gradient-layer .text-container button.contact-button,
  .main-container .gradient-layer .form-container .form{
    width: 100%;
    left: 0;
  }
  .main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper{
    width: 120px;
  }
  .main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper i{
    display: none;
  }
  .main-container .gradient-layer .form-container .contact-button{
    display: none;
  }

  .main-container .gradient-layer .form-container .form .signup-button{
    width: 115px;
  }
  
  .main-container .gradient-layer .form-container .contact-mobile-button{
    display: block;
    width: 82%;
    margin: 0 auto;
  }
}

@media only screen and (min-height: 1000px) and (min-width: 1024px){
  .main-container .gradient-layer .form-container .form{
    top: 550px; 
  }
} */
