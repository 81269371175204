

body{
    overflow: hidden;
    background:#f0f2f5;
}
.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger{
    background: none;
}
.ant-layout-sider-zero-width-trigger:hover {
    background: #192c3e00;
}
.ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: -54px !important;
    font-size: 24px;
    left: 0; }



.weekly-row{
    padding-left: 20px;
    padding-right: 20px;
}
.weekly-row h3{
    text-align: left;
}
.weekly-row .ant-card-head{
    min-height: 0px;
    padding: 0;
    height: 35px;
    font-size: 15px;
}
.weekly-row .ant-card-head-wrapper{
    height: inherit;
}
.weekly-row .ant-card-head-title{
    background-color: #1e1c5d;
    color: #fff;
    height: inherit;
    padding: 5px;
}
.weekly-row .ant-card-body{
    padding: 0;
}
.weekly-row .ant-card-body button.add-schedule-button{
    width: 100%;
    height: 90px;
    padding: 0;
    border: 0;
    background-color: #fff;
    border-radius: 0;
    color: #0000007a;
    font-size: 40px;
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #248eef; 
    border-radius: 5px;
  }
  
.inner-body-wrapper{
    overflow-y: scroll;
    height: 90vh;    
}
.inner-body-wrapper main{
    position: relative;
    min-height: auto;
}
.ant-menu-item .ant-menu-item-selected {
    Margin-top: 0px;}

div.timesheet-drawer{
    position: absolute;
}

div.timesheet-drawer button.select-button{
    margin-top: 10px;
    border-radius: 0;
    min-height: 60px;
    height: auto;
}
div.timesheet-drawer .submit-button{
    margin-top: 15px;
}

div.timesheet-drawer button.select-button h4{
    margin-top: 10px;
}

.new-timesheet-btn i{
    padding-right: 10px;
    border-right: 1px solid #fff;
}

.ant-drawer-body p{
    text-align: left;
}

div.steps-content{
    border: none;
    position: relative;
    overflow: hidden;
    border-radius: 0;
}
div.ant-drawer-header{
    background-color: #1e1c5d;
    border-radius: 0;
}

div.ant-drawer-header div.ant-drawer-title{
    color: #fff;
}
.ant-card-body button.shift-add-button.add-schedule-button{
    background-color: #74a274fc;
}
.selected-time{
    position: relative;
    top: 5px;
    font-size: 16px;
}
.selected-time h5{
    color: #fff;
}

button.save-schedule-button {
    width: 100%;
    height: 125px;
    border: 0;
    border-radius: 0;
    font-size: 18px;
}
button.save-schedule-button-mob {
    width: 100%;
    margin-top: 6px;
    height: 54px;
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    font-size: 18px;
}
button.skip-button,span.skip-button{
    float: left;
    left: 10px;
}
button.prev-button,span.prev-button{
    float: right;
    right: 15px;
    margin-left: 2px;
}
.timesheet-list-add{
    display: flex;
    color: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    background-color: #1890ff;
    margin: 7px;
}
button.timesheet-btn{
    padding: 0px;
    border-radius: 0;
    color: #0000007a;
    font-size: 30px;
    height: 54px;
    border: 0px solid transparent;
}
button.shift-add-buttonlist{
    color: #0000007a;
    background-color: #74a274fc;
    border-radius: 0;
    height: 54px;
}
p.timesheet-list-add-p{
    padding-top: 9px;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
}
.ant-layout-sider {
    /* position: absolute ; */
    z-index: +1;
    height: 97vh;}
  

.ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: -43px !important;
    font-size: 27px !important;
    left: 0; }

button.shift-add-buttonlist .selected-time{
    display: block;
}
button.skip-button i {
    font-weight: 500;
}
.weekly-row .ant-card{
    border: none;;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
}
.timesheet-card{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
    position: relative;
    top: 18px;
}

.timesheet-card .ant-card-head{
    padding: 0;
    background-color: #1e1c5d;
    color: #fff;
}
.timesheet-card .ant-list-items{
    text-align: left;
    color: #1890ff;
}
.timesheet-card .ant-card-head-title{
    text-align: left;
    padding-left: 20px;
}
.timesheet-card .ant-card-extra{
    padding-right: 20px;
    color: #fff;
    font-size: 18px;
}
.timesheets-row{
    padding: 0 50px 50px 50px;
    text-align: left;
}
.timesheets-row .timesheet{
    padding: 20px;
}
.weekly-row .ant-col{
    margin-left: 15px;
    padding-bottom: 10px;
}
.weekly-row .ant-col .ant-btn-group{
    float: right;
    right: 10px;
}
button.ant-btn-primary h4, button.ant-btn-primary h5{
    color: #fff;
}
.schedule-action-btns{
    position: relative;
    top: 15px;
}
.non-submit-btns{
    float: left;
    position: relative;
    left: 40px;
}
.non-submit-btns button{
    margin-right: 10px;
}
.submit-btn{
    float: right;
    position: relative;
    right: 45px;
}
p.error-message{
    color: #ed5959;
}
.edit-shift button.select-button{
    min-height: 65px;
    margin-top: 10px;
    padding: 5px;
}
button.ant-btn.add-schedule-button[disabled]{
    background-color: #e5d9d9;
}
button.timesheetbtn{
    float: right;
    width: 60px;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    height: 60px;
    font-size: 37px;
}
.timesheet-panel div.ant-collapse-header{
    padding: 0;
    height: 50px;
    background-color: #1e1c5d;
    color: #fff !important;
    font-size: 14px;
    font-weight: 500;
}
.single-timesheet-switch{
    width: 170px;
    margin: 0;
    padding: 0;
    position: relative;
    right: 20px;
}
.timesheet-available{
    font-size: '13px';
    color:rgba(0, 0, 0, 0.45);
}
.single-timesheet-card-shift{
    text-align: center;
    padding: 0;
    width: 120px;
    margin: 0;
    position: relative;
    right: 50px;
}
.timesheet-panel div.ant-collapse-content-box{
    padding: 0;
}
.timesheet-indicator{
    font-weight: 500;
    font-size: 14px;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
}
.timesheet-indicator .title{
    background-color: #248eef;
    height: 100%;
    padding: 18px;
    color: #fff;
}
.timesheet-indicator .navigator{
    background-color: #fff;
    height: 100%;
    padding: 10px;
    height: inherit;
    float: right;
    text-align: center;
}
.timesheet-indicator .navigator button{
    font-size: 25px;
}
@media only screen and (max-width: 1024px) {
    .ant-layout-sider {
        position: absolute !important;}
}

@media only screen and (max-width: 800px) {
    
    .timesheets-row .timesheet {
        padding: 7px;
        padding-bottom: 20px;
    }
    .single-timesheet-switch{
        width: 130px;
        margin-left: 0px;
        right: 150px;
    }

    .timesheets-row {
        padding: 0 0px 50px 2px;
        text-align: left;
    }
    .ant-list-item-meta-title {
        width: 93px;
    }
    .timesheet-indicator .title {
        padding: 12px;
    }

    .timesheet-indicator .navigator {
        padding: 5px;
    }

    .timesheet-card-h4{
        float: right;
        margin-left: 8px;
    }
    .single-timesheet-card-shift{
        text-align: left;
        padding-right: 0px;
    }
    .timesheet-available{
        display: none;
    }
    button.shift-add-buttonlist .selected-time {
        width: 50px;
        align-content: center;
        margin: 0px auto;
        display: inherit;
    }
    .selected-time h5{
        line-height: 1;
        margin: 2px;
        padding: 0;
        width: 50px;
        font-size: 11px;
    }
}

@media only screen and (max-width: 600px){
    .weekly-row{
        padding: 0;
    }
    .weekly-row .ant-col{
        margin: 0;
    }
    .non-submit-btns{
        position: absolute;
        /* bottom: 65px; */
        top: -95px;
        width: 100%;
        text-align: center;
        /* margin: 0; */
        float: unset;
        left: unset;
    }
    .submit-btn{
        float: right;
        right: 10px;
        margin-bottom: 30px;
        /* float: left; */
    }
    .timesheet-card .ant-card-body{
        padding: 5px;
    }
    .timesheet-card .single-timesheet-switch{
        width: 80px;
        right: 10px;
    }
    .timesheet-card-h4{
        float: left;
    }
    .single-timesheet-card-shift{
        width: 100px;
        right: 15px;
    }
}

@media only screen and (max-width: 340px){
    .timesheet-card .single-timesheet-card-shift{
        right: 0;
    }
    .timesheet-card .single-timesheet-switch{
        width: 130px;
    }
    .timesheet-card-h4{
        float: right;
        margin-left: 8px;
    }
}