.text-field Input{
    border: 1px solid rgba(0, 0, 0, 0.21);
    padding: 7px;
    width: 67%;
    border-radius: 4px;
    box-shadow: none;
}
button.ant-button > i.anticon{
    padding-right: 5px;
}
/* Ant Tooltip */
.ant-tooltip{
    z-index: 10000 !important;
}
/* Table Title */
div.table-title{
    height: 35px;
}
div.table-title h2{
    color: #fff;
    width: 20%;
    float: left;
}
div.table-title .select-filter{
    width: 35%;
    float: left;
}
div.table-title .ant-input-search{
    display: block;
}
.ant-input-search .ant-input{
    /* width: 30%; */
    float: right;
}
.search-input.ant-input-search .ant-input-suffix{
    margin-top: 15px;
}
.ant-list-item-meta-title span p{
    font-weight: 400;
    color: #b0a3a3;
}
div.checkbox-container,div.datepicker-container div.ant-form-item-control{
    text-align: left;
}
div.ant-form-extra{
    text-align: left;
}
span.ant-time-picker{
    width: 100%;
    margin-top: 10px;
}
.switch-post-label{
    font-weight: 500;
    padding-left: 20px;
}
div.text-area div.ant-form-item-label{
    text-align: left;
}
.image-input div.ant-upload-select-picture-card{
    border: none;
    border-radius: 50%;
    background-color: #bebaba;
}
.image-input div.ant-upload-list-item-list-type-picture-card{
    padding: 0;
    border: none;
    width: 140px;
    height: 140px;
}
.image-input div.ant-upload-list-item-list-type-picture-card .ant-upload-list-item-info{
    border-radius: 50%;
    width: 140px;
    height: 140px;
}
.image-input .ant-form-item-label{
    min-height: 100px;
}
.image-input label{
    top: 50px;
}
.image-input .ant-upload.ant-upload-select-picture-card{
    width: 140px;
    height: 140px;
}
.image-input .ant-upload-list-picture-card-container{
    width: 140px;
    height: 140px;
}
.image-input i{
    font-size: 38px;
    color: #fff;
}
.image-input .ant-upload-select-picture-card:hover{
    background-color: #000;
    opacity: 0.7;
}
.image-input .ant-upload.ant-upload-select-picture-card .ant-upload{
    padding: 0;
}

/** Modal Box **/
div.ant-modal-root div.ant-modal-content div.ant-modal-header{
    background-color: #1890ff;
}
div.ant-modal-root div.ant-modal-content div.ant-modal-header .ant-modal-title,
div.ant-modal-root div.ant-modal-content .ant-modal-close-x{
    color: #fff;
}
div.ant-modal-root div.ant-modal-content div.ant-modal-header .ant-modal-title i{
    padding-right: 5px;
}
/** Field Erros  **/
.field-error{
    color: red;
}
/* div.ant-modal-content, div.ant-modal-header{
    border-radius: 0px;
}
div.ant-modal-header{
    padding: 10px;
    background-color: #1e1c5d !important;
}
div.ant-modal-title{
    font-size: 14px !important;
    font-weight: 700;
}
button span.ant-modal-close-x{
    height: 42px;
    line-height: 40px;
}
div.ant-modal-content div.ant-modal-footer{
    padding: 10px 16px;
    background-color: #d8d8e19e;
}
div.ant-modal-footer button{
    border-radius: 0;
    height: 26px;
}
div.ant-modal-content div.ant-modal-body{
    background-color: #fff;
} */

/** Receipt Card **/

.receipt-card .ant-card-head{
    border-bottom: 5px solid #1890ff;
    color: #1890ff;
}
.receipt-card .ant-card-head .receipt-subtitle{
    display: block;
    position: relative;
    left: 6px;
    font-weight: 600;
    font-size: 13px;
    color: #a19a9a;
}

/* .receipt-card .ant-card-head-title, */
.receipt-card .ant-card-extra{
    padding: 0;
}

.receipt-card .data-container{
    position: relative;
    top: 20px;
    width: 95%;
    margin: 0 auto;
}
.receipt-card .data-container label{
    font-size: 14px;
    font-weight: 500;
    color: #1890ff;
}