/* div.requests-container div.offers-container div.list-content{
  height: 450px;
  border-right: 2px solid #eee7e7;
  overflow-y: auto;
  padding-left: 20px !important;
} */
.offers-list-container .ant-card-body{
  padding: 0;
}
div.requests-container div.list-content{
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-height: 550px;
  border-right: 1px solid #eee7ee;
}
div.requests-container div.list-content li.ant-list-item{
  border-radius: 0;
  padding-left: 30px;
}
div.requests-container div.list-content .ant-list-item-meta-description{
  font-weight: 400;
  padding-left: 28px;
}
.offer-content{
  height: 550px;
  overflow-y: auto;
}
.active-list-item{
    background: #1890ff;
    border-radius: 5px;
}
.active-list-item h4,
.active-list-item .ant-list-item-meta-description{
  color: #fff;
}
.list-item{
  cursor: pointer;
}
.ant-descriptions-item-label{
  font-weight: 700 !important;
}
.list-arrow{
  display: none;
  color: #fff;
  font-size: 22px;
  padding-right: 10px;
}
.active-list-item .list-arrow{
  display: block;
}
.offers-container .action-buttons{
  position: relative;
  top: 20px;
  float: right ;
}
.offers-container .action-buttons .ant-btn{
  margin-right: 20px;
}
.offers-container .offer-status{
  text-transform: uppercase;
}
.offers-container .requests-checkbox{
  padding-left: 25px;
}

@media only screen and (max-width: 600px){
  div.requests-container div.list-content{
    min-height: 0;
  }
}